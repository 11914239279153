export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: localStorage.getItem('user') || '',
        subscriptionLevel: localStorage.getItem('subscriptionLevel') || '',
        subscriptionFrequency: localStorage.getItem('subscriptionFrequency') || '',
        subStatus: localStorage.getItem('subStatus') || 'inactive',
        authenticated: localStorage.getItem('authenticated') === "true",
        paidStatus: localStorage.getItem('paid') === "true",
        pendingStatus: localStorage.getItem('pendingStatus') || "not-pending",
        nextBillingDate: localStorage.getItem('nextBillingDate') || false,
        introStatus: localStorage.getItem('introStatus') || 'shown',
        upgradeStatus: localStorage.getItem('upgradeStatus') || '',
    }),
    actions: {
        clear() {
            this.user = '';
            this.authenticated = false;
            this.subStatus = 'inactive';
            this.subscriptionLevel = '';
            this.subscriptionFrequency = '';
            this.pendingStatus = 'not-pending';
            this.nextBillingDate = false;
            this.paidStatus = false;
            this.introStatus = 'shown';
            this.upgradeStatus = '';

            localStorage.removeItem('authenticated');
            localStorage.removeItem('user');
            localStorage.removeItem('subStatus');
            localStorage.removeItem('subscriptionLevel');
            localStorage.removeItem('subscriptionFrequency');
            localStorage.removeItem('pendingStatus');
            localStorage.removeItem('nextBillingDate');
            localStorage.removeItem('paid');
            localStorage.removeItem('introStatus');
            localStorage.removeItem('upgradeStatus');
        },
        authenticate(authState){
            this.authenticated = authState;
            localStorage.setItem('authenticated', authState);
        },
        setUser(user){
            this.user = user;
            localStorage.setItem('user', user);
        },
        setSubStatus(subStatus){
            this.subStatus = subStatus;
            localStorage.setItem('subStatus', subStatus);
        },
        setSubscriptionLevel(subscriptionLevel){
            this.subscriptionLevel = subscriptionLevel;
            localStorage.setItem('subscriptionLevel', subscriptionLevel);
        },
        setSubscriptionFrequency(subscriptionFrequency){
            this.subscriptionFrequency = subscriptionFrequency;
            localStorage.setItem('subscriptionFrequency', subscriptionFrequency);
        },
        setPendingStatus(pendingStatus){
            this.pendingStatus = pendingStatus;
            localStorage.setItem('pendingStatus', pendingStatus);
        },
        setNextBillingDate(nextBillingDate){
            this.nextBillingDate = nextBillingDate;
            localStorage.setItem('nextBillingDate', nextBillingDate);
        },
        setPaidStatus(paidStatus){
            this.paidStatus = paidStatus;
            localStorage.setItem('paidStatus', paidStatus);
        },
        setIntroStatus(introStatus){
            this.introStatus = introStatus;
            localStorage.setItem('introStatus', introStatus);
        },
        setUpgradeStatus(upgradeStatus){
            this.upgradeStatus = upgradeStatus;
            localStorage.setItem('upgradeStatus', upgradeStatus);
        },
    },
})
